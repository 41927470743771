
import { defineComponent } from 'vue';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Link from '../../../atomic/atoms/Link/Link.vue';
import Input from '../../../atomic/atoms/Input/Input.vue';
import AuthTemplate from '../../../atomic/templates/AuthTemplate/AuthTemplate.vue';
import { FormBuilder, FormControl } from '../../../utilities/Forms';
import { Validators } from '../../../utilities/Validators/Validators';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  components: {
    'auth-template': AuthTemplate,
    'atomic-button': Button,
    'atomic-link': Link,
    'atomic-input': Input,
  },

  data(): {
    inputType: string;
    formData: FormBuilder;
  } {
    return {
      inputType: 'password',
      formData: new FormBuilder({
        email: new FormControl('', [Validators.email, Validators.required]),
      }),
    };
  },

  computed: {
    ...mapGetters(['emailAddress']),
  },

  methods: {
    ...mapActions(['sendResetCode']),
    async submitForm(): Promise<void> {
      this.formData.markAllAsTouched();

      if (this.formData.hasErrors) {
        return;
      }

      const emailSent = await this.sendResetCode(this.formData.controls.email.value);

      if (emailSent) {
        this.$router.push('code');
      } else {
        this.$notify({
          title: 'SOMETHING LOOKS OFF',
          text:
            'Something went wrong. Please try again. If you continue to experience an issue, please either contact your Engagement Agents account manager, or call (416) 577-7326',
          type: 'error',
          duration: 5000,
        });

        setTimeout(() => {
          this.$router.push('login');
        }, 2000);
      }
    },
    validateControl(controlName: string): void {
      this.formData.controls[controlName].validate();
    },
    toggleShow(): void {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
  },
});
